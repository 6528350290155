<template>
  <div>
    <PersonnelEducation/>
  </div>
</template>

<script>
import PersonnelEducation from '../../components/personnelEducation.vue';

export default {
    name: 'HRvecPersonnelEducation',
    data() {
        return {};
    },
    mounted() {
    },
    methods: {},
    components: { PersonnelEducation }
};
</script>

<style lang="scss" scoped>

</style>