<template>
  <div>
    <v-container class="text_google">
      <base-material-card
        icon="mdi-clipboard-text"
        title="วุฒิการศึกษา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="personnel_educations"
          :search="search"
        >
          
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ApiKey: "HRvec2021",
      loading: false,
      search: "",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      currentPK: null,
      headers: [
        { text: "วิทยาลัย", align: "center", value: "college_name" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "title_s" },
        { text: "ชื่อ", align: "left", value: "frist_name" },
        { text: "นามสกุล", align: "left", value: "last_name" },
        { text: "ประเภทสาขาวิชา", align: "left", value: "name_branch" },
        { text: "ระดับการศึกษา", align: "left", value: "education_level" },
        { text: "คณะวิชา", align: "left", value: "faculty_name" },
        { text: "สาขาวิชา", align: "left", value: "branch_name" },
        { text: "จบจาก", align: "left", value: "academy_name" },
        { text: "ปีที่จบ", align: "center", value: "year_finish" },
        { text: "ผลการเรียน", align: "center", value: "academic_results" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {},
      personnel_educations: [],
      userinfos:[],
    };
  },

  async mounted() {
    this.userQuery();
    this.personnel_educationsQueryAll();
  },

  methods: {
    async userQuery() {
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http.post("user.php", {
        ApiKey: this.ApiKey,
        user_ID: userSession.user_ID
      });
      this.userinfos = result.data;
    },

    async personnel_educationsQueryAll() {
      if (this.userinfos.user_status == "S") {
        this.loading = true;
        let result = await this.$http
          .post("personnel_education.php", {
            ApiKey: this.ApiKey,
            college_code: this.userinfos.user_code,
            supervisions: "ok",
          })
          .finally(() => (this.loading = false));
        this.personnel_educations = result.data;
      } else if (this.userinfos.user_status == "C") {
        this.loading = true;
        let result = await this.$http
          .post("personnel_education.php", {
            ApiKey: this.ApiKey,
            collegeVei: this.userinfos.user_code
          })
          .finally(() => (this.loading = false));
        this.personnel_educations = result.data;
      } else if (this.userinfos.user_status == "G") {
        this.loading = true;
        let result = await this.$http
          .post("personnel_education.php", {
            ApiKey: this.ApiKey,
            province_ID: this.userinfos.province_ID
          })
          .finally(() => (this.loading = false));
        this.personnel_educations = result.data;
      }
    }
  },

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
